import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import BackLink from "../components/BackLink"
import SEO from "../components/SEO"

const ComponentName = ({data}) => {
  const { content, title, description, youtube} = data.video;
  return <Layout indicator themeSwitch>
    <SEO title={title} description={description.description}/>
    <section className="blog-post section-center-narrow section-padding">
    <BackLink title="All Posts" page="media"/>
      <div className="section-center section-padding">
        <article className="blog-content">
          <h1>{title}</h1>
          <iframe id="ytplayer" type="text/html" width="100%" height="500px"
            src={youtube}
            frameborder="0"></iframe>
        </article>
      </div>
    </section>
  </Layout>
}

export const query = graphql`
  query GetSingleMonthlyVideo($slug: String) {
    video: contentfulMonthlyVideos(slug: { eq: $slug }) {
      title
      description {
        description
      }
      youtube
    }
  }
`

export default ComponentName
